import './scripts/common';
import './scripts/common-nav';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import {onFind} from "@elements/init-modules-in-scope";

import Tab from 'bootstrap.native/dist/components/tab-native.esm';
onFind('[data-toggle="tab"]',function (element) {
    new Tab(element);
});

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import * as rangeSlider from '@elements/range-slider';
rangeSlider.init();

import * as ajaxForm from './scripts/ajax-form';
ajaxForm.init();

import * as ajaxFormExtend from './scripts/ajax-form-extend';
ajaxFormExtend.init();

import * as overlayToggle from './scripts/overlay-toggle';
overlayToggle.init();

import * as tourMap from './scripts/tour-map';
tourMap.init();

import * as gallery from './scripts/gallery';
gallery.init();

import * as centerActiveTab from './scripts/center-active-tab';
centerActiveTab.init();

import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
onFind('[data-toggle="tooltip"]',function (element) {
    new Tooltip(element);
});

import * as lightbox from './scripts/elements-packages/lightbox';
lightbox.init();